html, body{
    padding: 0;
    margin: 0;
    background: #F5F5F5;
}
p{
    margin: 0;
    padding: 0;
}

a,a:hover,a:active,a:visited,a:link,a:focus{
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
    outline:none;
    background: none;
    text-decoration: none;
}

input,textarea{
    outline: none;
    border: none;
    resize:none ;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #ccc;
}
input:-moz-placeholder, textarea:-moz-placeholder {
    color: #ccc;
}
ul,li{ 
    padding:0;
    margin:0;
    list-style:none
}
.emoji-mart {
    width: 100% !important;
}
.emoji-mart-scroll{
    height: 210px !important;
}
.emoji-mart-search{
    display: none;
}
body::-webkit-scrollbar {
    display: none;
}
*{  
    -webkit-touch-callout:none;  /*系统默认菜单被禁用*/  
    -webkit-user-select:none; /*webkit浏览器*/  
    -webkit-touch-select:none;
    -khtml-user-select:none; /*早期浏览器*/  
    -moz-user-select:none;/*火狐*/  
    -ms-user-select:none; /*IE10*/  
    user-select:none;  
} 
input,textarea {
    -webkit-user-select:auto; /*webkit浏览器*/
    margin: 0px;
    padding: 0px;
    outline: none;
}
.point-line-list{
    margin-top: 10px;
}
.point-line-list .point-value {
    color: #E84345;
}


*{ -webkit-tap-highlight-color: rgba(0,0,0,0);-webkit-tap-highlight-color: transparent; /* For some Androids */ }

.to-login-modal .info-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    padding: 0 30px;

}
.to-login-modal .info-item .left{
    display: flex;
    align-items: center;
}
.to-login-modal .info-item .info-item-value{
    width: 80px;
}
.to-login-modal .info-item .icon-jinbi{
    width: 20px;
    margin-right: 15px;
}